export const Translations = [{
en: {
        "title": "Multi-language app",
        "label": "Select another language!",
        "space": "Lab",
        "panel": "Panel",
        "offre": "Our offers",
        "contact": "Contact",
        "services": "Services",
        "btn0": "Participate in a study",
        "btn1": "Start a research",
        "btn2": "Start a research",
        "btn3": "En savoir plus",
        "btn4": "Visit the lab",
        "btn5": "Select",
        "home1": "Your health research",
        "home2": "in serenity",
        "home3": "",
        "home4": "A ready-to-go solution for organising your user and consumer studies in healthcare.",
        "home5": "Participants recruitment",
        "home6": "Facilities rental",
        "home7": "Moderation of research",
        "home8": "+ 200m2",
        "home9": "of spaces dedicated to health studies",
        "home10": "+ de 5000",
        "home11": "patients and healthcare profesionnals in the lab",
        "home12": "General public",
        "home13": "Patients",
        "home14": "Nurses",
        "home15": "Care assistants",
        "home16": "Laboratory technicians",
        "home17": "Biologists",
        "home18": "Doctors",
        "home19": "Surgeons",
        "home20": "Dentists",
        "home21": "Pharmacists",
        "home22": "Gynaecologists - obstetricians",
        "home23": "Dermatologists ...",
        "home0-1": "Ready-to-go solutions",
        "home0-2": "Just one partner, and we'll take care of everything! All you have to do is run your sessions. Unless you want us to take care of that too.",
        "home0-3": "Relevant participants",
        "home0-4": "We recruit your patient users or healthcare professionals to meet the specific needs of your study.",
        "home0-5": "Secure your project schedule",
        "home0-6": "Depending on the profile required, the study can start 1 to 4 weeks after the recruitment is launched.",
        "profil1": "Find ",
        "profil2": "the right profiles ",
        "profil3": "to carry out your projects",
        "profil4": "As a specialist in healthcare recruitment, HealthLab brings you closer to your users. Whether you are looking for healthcare professionals,  patients or general public, we can provide you with our panel in compliance with the relevant regulations.",
        "service1-0": "Anti-gift law and transparency procedure",
        "service1-1": "We can manage the processes involved in the law governing benefits and transparency for you.",
        "service2-0": "Incentives management",
        "service2-1": "From setting up agreements to incentives, we take care of all these essential but time-consuming stages.",
        "service3-0": "Specialised profiles",
        "service3-1": "Our knowledge of the healthcare environment and the diversity of our user panel means we can offer you precise profiles and specialised healthcare professionals.",
        "service4-0": "Representative participants",
        "service4-1": "To ensure your researches are as relevant as possible, we can help you define a representative panel of your future users.",
        "lab0": "The Lab : ",
        "lab1": "an area dedicated to the evaluation of health products",
        "lab2": "The lab is a fully-equipped 200m² space where you can carry out your user and consumer research with complete peace of mind: audiovisual equipment, broadband wifi and medical equipment are all available.",
        "lab3": "User test rooms",
        "lab4": "One-way glass control room",
        "lab5": "Co-creation space",
        "lab6": "Meeting rooms",
        "lab7": "Reception and dedicated access",
        "lab8": "Event space",
        "lab9": "On demand",
        "lab10": "Medical equipment",
        "lab11": "Medical training and simulation dummies",
        "lab12": "Eye Tracking",
        "lab15": "Whiteboards and paperboards",
        "lab13": "Reception of participants with coffee and croissants",
        "lab14": "Meal management",
        "salle0-0": "Main reception",
        "salle0-1": "To welcome your participants, customers, partners or staff with complete peace of mind, we have two separate reception and access areas.",
        "salle1-0": "User testing rooms",
        "salle1-1": "Our user test rooms are equipped to enable you to test your products with your future users: cameras, audio transmission, eye tracking, etc.",
        "salle2-0": "Control room",
        "salle2-1": "Thanks to our control room and its one-way glass, you can observe and hear the participants testing your product without disrupting the test.",
        "salle3-0": "Co-creation space",
        "salle3-1": "A modular space designed to encourage collaboration: focus groups, creativity workshops, co-design... the choice is yours.",
        "salle4-0": "Meeting room +12pers.",
        "salle4-1": "Whether it's for a brainstorming session, a conference or a client meeting, get the perfect place to turn your ideas into reality. Take advantage of a warm and inviting space that can be fully adapted to your needs: screens, tables and extra chairs are all available.",
        "salle5-0": "Waiting room",
        "salle5-1": "As well as a test room, you can also have a dedicated reception area to keep your participants waiting.",
        "salle6-0": "Meeting room 8pers",
        "salle6-1": "To bring together your participants, customers, partners or colleagues, book our modern, fully-equipped meeting room for productive and inspiring discussions. High-speed Wi-Fi, audiovisual equipment and a comfortable setting for working in total serenity.",
        "salle7-0": "Medical environment",
        "salle7-1": "Each of our rooms can be equipped to recreate a medical environment: medical equipment, simulation and injection dummies, infusion arms, laboratory equipment, etc.",
        "salle8-0": "Events area",
        "salle8-1": "Our large, fully-equipped room can accommodate all your needs: seminars, meetings, focus groups, training sessions or private events. With plenty of flexible tables and chairs, a kitchen and comfortable surroundings, we have everything you need to organise a smooth and successful event.",
        "confiance1": "They put their ",
        "confiance2": "trust",
        "confiance3": " in us",
        "verbatim1": "Marie La Cliente",
        "verbatim2": "Responsable QARA",
        "verbatim3": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.",
        "verbatim4": "",
        "numbers1": "One space for all ",
        "numbers2": "your research",
        "numbers3": "We can help you successfully achieve your health studies, whatever your field of interest.",
        "numbers4": "Consumer research and marketing evaluations",
        "numbers5": "Formatives and sommatives evaluation (EN 62366)",
        "numbers6": "User experience and UX design",
        "numbers7": "Usage studies, interviews, questionnaires",
        "numbers8": "User testing and AB testing",
        "numbers9": "Design workshops and focus groups",
        "numbers10": "FMEA / AMDEC and risks evaluations",
        "numbers11": "Handling and leaflet readability tests",
        "offres1": "Flexible offers ",
        "offres2": "to suit your needs",
        "offres3": "Take advantage of our ready-to-use packages or define your offer on-demand to start your study straight away.",
        "offres1-0":"Package",
        "offres1-1":"General public",
        "offres1-2":"Number : 7 participants",
        "offres1-3":"Profile : General public",
        "offres1-4":"Format: 1 hour face-to-face",
        "offres1-5":"1 day room rental",
        "offres1-6":"Other options on demand",
        "offres1-7":"From",
        "offres2-0":"Package",
        "offres2-1":"Patients",
        "offres2-2":"Number : 7 participants",
        "offres2-3":"Profile : Patients",
        "offres2-4":"Format: 1 hour face-to-face",
        "offres2-5":"1 day room rental",
        "offres2-6":"Other options on demand",
        "offres2-7":"From",
        "offres3-0":"Package",
        "offres3-1":"Healthcare professionals",
        "offres3-2":"Number : 7 participants",
        "offres3-3":"Profile : Nurses",
        "offres3-4":"Format: 1 hour face-to-face",
        "offres3-5":"1 day room rental",
        "offres3-6":"Other options on demand",
        "offres3-7":"From",
        "offres4-0":"Study",
        "offres4-1":"On-demand",
        "offres4-2":"Number of participants depending on your requirements",
        "offres4-3":"Participants profiles depending on your requirements",
        "offres4-4":"Face-to-face or remotely",
        "offres4-5":"Room rental available",
        "offres4-6":"Moderation of sessions possible",
        "offres4-8":"Options sur demande",
        "offres4-7":"Available on request",
        "FAQ1-0": "What is",
        "FAQ1-1": "MedLab",
        "FAQ1-2": "Lille ?",
        "FAQ1-3": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "FAQ2-0": "For what type of ",
        "FAQ2-1": "study ",
        "FAQ2-2": "can we call on MedLabLille ?",
        "FAQ2-3": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "FAQ3-0": "What ",
        "FAQ3-1": "profiles ",
        "FAQ3-2": "can your recruit ?",
        "FAQ3-3": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "FAQ4-0": "What if I want to do",
        "FAQ4-1": "online ",
        "FAQ4-2": "tests ?",
        "FAQ4-3": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "contact0": "Nous contacter",
        "contact1": "Des questions ou un projet d'étude ?",
        "contact2": "Nom *",
        "contact3": "Email *",
        "contact4": "Votre message *",
        "contact5": "Envoyer",
        "contact6": "Dites nous en un peu plus, et nous",
        "contact7": "vous recontactons sous 48h.",
        "contact8": "Votre demande :",
        "contact9": "N'hésitez pas à nous contacter.",
        "contact10": "A côté du CHRU de Lille",
        "contact11": "A proximité du métro de Lille (Arrêt CH Eurasanté)",
        "contact12": "Accessible en train depuis Paris (1 heure)",
        "contact13": "Nous trouver",
        "devis0": "Demandez votre devis dès maintenant.",
        "devis1": "Nous vous recontacterons sous 48h.",
        "devis2": "Nom",
        "devis3": "Prénom",
        "devis4": "Email",
        "devis5": "Téléphone",
        "devis6": "Votre entreprise",
        "devis7": "Type de demande",
        "devis8": "Courte description de votre demande",
        "devis9": "Comment souhaitez-vous être recontacté(e)?",
        "footer0": "Rejoignez-nous",
        "footer1": "Mention légales / CGU",
        "footer2": "Politique de protection des données personnelles",
        "footer3": "MedLabLille",
        "footer4": " a brand proposed by",
        "footer5": "par UseConcept and HealthSquad",
        "option2-1":"Par Mail",
        "option2-2":"Par Téléphone",
    },
fr: {
        "title": "Multi-language app",
        "label": "Select another language!",
        "space": "Le lab",
        "panel": "Le panel",
        "offre": "Nos offres",
        "contact": "Contact",
        "services": "Services",
        "btn0": "Participer à une étude",
        "btn1": "Lancer une étude",
        "btn2": "Lancer une étude",
        "btn3": "En savoir plus",
        "btn4": "Visiter le lab",
        "btn5": "Choisir",
        "home1": "Vos études santé en",
        "home2": "toute sérénité",
        "home3": "",
        "home4": "Une offre clés en main pour oraniser vos études utilisateurs et consomateurs en santé.",
        "home5": "Recrutement des participants",
        "home6": "Location d'espaces",
        "home7": "Modération des études",
        "home8": "+ 200m2",
        "home9": "d'espace dédié aux études en santé",
        "home10": "+ de 5000",
        "home11": "patients et professionnels de santé dans le lab",
        "home12": "Grand public",
        "home13": "Patients",
        "home14": "Infirmiers",
        "home15": "Aides-soignants",
        "home16": "Techniciens de laboratoire",
        "home17": "Biologistes",
        "home18": "Médecins",
        "home19": "Chirurgiens",
        "home20": "Dentistes",
        "home21": "Pharmaciens",
        "home22": "Gynécologues - obstétriciens",
        "home23": "Dermatologues ...",
        "home0-1": "Des études clés en main",
        "home0-2": "Un seul interlocuteur, on s'occupe de tout ! Vous n'avez plus qu'à animer vos sessions. Sauf si vous souhaitez que nous nous en chargions également.",
        "home0-3": "Pertinence des participants",
        "home0-4": "Nous recrutons vos utilisateurs patients ou professionnels de santé pour répondre aux besoins spécifiques de votre étude.",
        "home0-5": "Sécurisez votre planning projet",
        "home0-6": "Selon le profil recherché, l'étude peut commencer 1 à 4 semaine après le lancement du recrutement.",
        "profil1": "Trouvez ",
        "profil2": "les bons profils ",
        "profil3": "pour mener à bien vos projets",
        "profil4": "Spécialiste du recrutement en santé, HealthLab vous rapproche de vos utilisateurs. Professionnels de santé, patients, ou tout-venants nous mettons à disposition notre panel dans le respect des réglementations.",
        "service1-0": "Loi anti-cadeaux et dispositif transparence",
        "service1-1": "Nous réalisons pour vous la gestion des processus concernant  la loi d'encadrement des avantages et du dispositif transparence.",
        "service2-0": "Gestion des rémunérations",
        "service2-1": "De la mise en place des conventions, jusqu'a la rémunération des participants, nous prenons en charge l'ensemble de ces étapes indispensables mais chronophages.",
        "service3-0": "Profils spécialisés",
        "service3-1": "Nos connaissances du milieu de la santé et la diversité de notre panel d'utlisateur nous permettre de vous proposer des profils précis et des professionnels de santé spécilialisés.",
        "service4-0": "Profils représentatifs",
        "service4-1": "Afin que vos études soient les plus pertinentes possibles, nous vous accomapgnons pour définir un panel représentatif de vos futurs utilisateurs.",
        "lab0": "Le lab : ",
        "lab1": "un espace dédié à l'évaluation des produits de santé",
        "lab2": "Le lab c'est plus de 200m2 entièrement équipé pour la réalisation de vos études utilisateurs et consommateurs en santé.",
        "lab3": "Salles de test utilisateur",
        "lab4": "Salle de régie",
        "lab5": "Espace de co-création",
        "lab6": "Salles de réunion",
        "lab7": "Accueil et accès dédié",
        "lab8": "Espace événements",
        "lab9": "Sur demande",
        "lab10": "Matériel médical",
        "lab11": "Mannequins d'entrainement médicaux et de simulation",
        "lab12": "Eye Tracking",
        "lab15": "Tableaux blancs, paperboard",
        "lab13": "Accueil des participants avec café/viennoiseries",
        "lab14": "Gestion des repas",
        "salle0-0": "Accueil principal",
        "salle0-1": "Pour accueillir vos participants, clients, partenaires ou collaborateurs en toute tranquilité, nous disposons de deux acceuils et accès distincts.",
        "salle1-0": "Salle de tests utilisateurs",
        "salle1-1": "Nos salles de tests utilisateurs sont équipées pour vous permettre de faire tester vos produits par vos futurs utilisateurs : caméras, retransmission audio, eye tracking, etc.",
        "salle2-0": "Salle de régie",
        "salle2-1": "Grace à notre salle de régie, vous pouvez venir observer les participants tester votre produit sans perturber le déroulé du test.",
        "salle3-0": "Espace de co-création",
        "salle3-1": "Un espace modulable et conçu pour favoriser la collaboration : focus group, atelier de créativité, co-conception ... à vous de choisir.",
        "salle4-0": "Salle de réunion +12pers.",
        "salle4-1": "Que ce soit pour un brainstorming, une conférence ou un rendez-vous client, offrez-vous le lieu parfait pour concrétiser vos idées. Profitez d'un espace chaleureux et entièrement modulable selon vos besoins : écrans, tables, chaises supplémentaires sont à disposition.",
        "salle5-0": "Salle d'attente",
        "salle5-1": "En plus d'une salle de test, vous pouvez également disposer d'un accueil dédié  afin de faire patienter vos participants.",
        "salle6-0": "Salle de réunion 8pers",
        "salle6-1": "Pour réunir vos participants, clients, partenaires ou collaborateurs, réservez notre salle de réunion moderne et équipée pour des échanges productifs et inspirants. Wi-Fi haut débit, matériel audiovisuel, et un cadre confortable pour travailler en toute sérénité.",
        "salle7-0": "Environnement médical",
        "salle7-1": "Chacune de nos salles peut être équipés de façon a recréer un environnement médical : matériel médical, mannequins de simulation et d'injection, bras de perfusion, matériel d'analyses, etc.",
        "salle8-0": "Espace événements",
        "salle8-1": "Notre grande salle entièrement équipée s’adapte à tous vos besoins : séminaires, réunions, focus groups, formations ou événements privés. Avec de nombreuses tables et chaises modulables, une cuisine à disposition et un cadre confortable, tout est réuni pour une organisation fluide et réussie.",
        "confiance1": "Ils nous font ",
        "confiance2": "confiance",
        "verbatim1": "Marie La Cliente",
        "verbatim2": "Responsable QARA",
        "verbatim3": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.",
        "verbatim4": "",
        "numbers1": "Un seul espace pour ",
        "numbers2": "toutes vos études",
        "numbers3": "Nous vous accompagnons pour réussir votre étude en santé quelque soit le domaine d'étude.",
        "numbers4": "Etudes consommateurs et évaluations marketing",
        "numbers5": "Evaluations formatives et sommatives (EN 62366)",
        "numbers6": "Expérience utilisateurs et UX design",
        "numbers7": "Etudes d'usage, entretiens, questionnaires",
        "numbers8": "Tests utilisateurs et AB Testing",
        "numbers9": "Ateliers de conception et focus group",
        "numbers10": "FMEA / AMDEC et évaluations des risques d'usage",
        "numbers11": "Tests de maniabilité et de lisibilité de notice",
        "offres1": "Des offres ",
        "offres2": "modulables selon vos besoins",
        "offres3": "Profitez de nos offres complètes ou définissez vos besoins à la carte pour démarrer votre étude sans attendre.",
        "offres1-0":"Journée",
        "offres1-1":"Tout venants",
        "offres1-2":"Nombre : 7 participants",
        "offres1-3":"Profils : Grand public",
        "offres1-4":"Modalitées : 1 heure en présentiel",
        "offres1-5":"Location de salle 1 journée",
        "offres1-6":"Options sur demande",
        "offres1-7":"A partir de",
        "offres2-0":"Journée",
        "offres2-1":"Patients",
        "offres2-2":"Nombre : 7 participants",
        "offres2-3":"Profils : Patients",
        "offres2-4":"Modalitées : 1 heure en présentiel",
        "offres2-5":"Location de salle 1 journée",
        "offres2-6":"Options sur demande",
        "offres2-7":"A partir de",
        "offres3-0":"Journée",
        "offres3-1":"Professionnels de santé",
        "offres3-2":"Nombre : 7 participants",
        "offres3-3":"Profils : Infimrier.es",
        "offres3-4":"Modalitées : 1 heure en présentiel",
        "offres3-5":"Location de salle 1 journée",
        "offres3-6":"Options sur demande",
        "offres3-7":"A partir de",
        "offres4-0":"Etude",
        "offres4-1":"Sur mesure",
        "offres4-2":"Nombre de participants selon vos besoins",
        "offres4-3":"Profils des participants selon votre étude",
        "offres4-4":"Réalisable en présentiel ou distanciel",
        "offres4-5":"Location de salle possible",
        "offres4-6":"Modération des sessions possibles",
        "offres4-8":"Options sur demande",
        "offres4-7":"Disponible sur devis",
        "FAQ1-0": "Qu'est ce que",
        "FAQ1-1": "MedLab",
        "FAQ1-2": "Lille ?",
        "FAQ1-3": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "FAQ2-0": "Pour quelles études faire appel à ",
        "FAQ2-1": "MedLab",
        "FAQ2-2": "Lille ?",
        "FAQ2-3": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "FAQ3-0": "Quels ",
        "FAQ3-1": "profils ",
        "FAQ3-2": "pouvez-vous recruter ?",
        "FAQ3-3": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "FAQ4-0": "Et si je veux faire des tests ",
        "FAQ4-1": "en ligne ",
        "FAQ4-2": "?",
        "FAQ4-3": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "contact0": "Nous contacter",
        "contact1": "Des questions ou un projet d'étude ?",
        "contact2": "Nom *",
        "contact3": "Email *",
        "contact4": "Votre message *",
        "contact5": "Envoyer",
        "contact6": "Dites nous en un peu plus, et nous",
        "contact7": "vous recontactons sous 48h.",
        "contact8": "Votre demande :",
        "contact9": "N'hésitez pas à nous contacter.",
        "contact10": "A côté du CHRU de Lille",
        "contact11": "A proximité du métro de Lille (Arrêt CH Eurasanté)",
        "contact12": "Accessible en train depuis Paris (1 heure)",
        "contact13": "Nous trouver",
        "labelcontact0": "Selectionner votre demande",
        "labelcontact1": "Obtenir un devis",
        "labelcontact2": "Obtenir des renseignements",
        "labelcontact3": "Visiter le Lab",
        "labelcontact4": "Prendre rendez-vous",
        "labelcontact5": "Autre",
        "devis0": "Demandez votre devis dès maintenant.",
        "devis1": "Nous vous recontacterons sous 48h.",
        "devis2": "Nom",
        "devis3": "Prénom",
        "devis4": "Email",
        "devis5": "Téléphone",
        "devis6": "Votre entreprise",
        "devis7": "Type de demande",
        "devis8": "Courte description de votre demande",
        "devis9": "Comment souhaitez-vous être recontacté(e)?",
        "footer0": "Rejoignez-nous",
        "footer1": "Mention légales / CGU",
        "footer2": "Politique de protection des données personnelles",
        "footer3": "MedLabLille",
        "footer4": " une marque proposée",
        "footer5": "par UseConcept et HealthSquad",
        "option2-1":"Par Mail",
        "option2-2":"Par Téléphone",
    }
}]