import React from 'react'
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { BsYoutube} from "react-icons/bs";
import logo from '../images/Logo_HealthSquad_blanc.png';
import logoUseconcept from '../images/logoUseconcept.png';
import { FaLinkedin, FaTiktok } from "react-icons/fa";
import {Translations} from '../constants/i18n';
import u111 from '../images/u111.avif';

const isActive = ({ isActive }) => `link ${isActive ? 'active' : ''}`

const Footer = ({Active, translation}) => {

console.log('ActiveRef',Active)
  const color = "#FFFFFF";

  const t = (value) => {
    return Translations[0][translation][value];
   }
  
        return ( <Container fluid> 
        <Row style={{  backgroundImage: `url(${u111})`,  backgroundSize: 'cover', 
          backgroundColor: 'rgba(26, 43, 110, 0.8)',
          backgroundBlendMode:'Overlay', paddingTop:60}}  fluid>
         <Col  xs={{ span: 8, offset: 2 }}>
        <Row className="justify-content-md-center" style={{marginTop:0}}>
        <Col className='text-start'>
          <Col style={{borderWidth:0, paddingBottom:60}}>
          <span style={{color: '#3BB9A3', fontSize:26, fontWeight: 700, fontFamily:'Poppins, sans-serif'}}>Med</span><span style={{color: 'white', fontSize:26, fontWeight: 700, fontFamily:'Poppins, sans-serif'}}>Lab</span><span style={{color: 'white', fontSize:26, fontWeight: 400, fontFamily:'Poppins, sans-serif',marginRight:0}}>Lille</span>
          <Col style={{fontSize: 17,borderWidth:0, backgroundColor:'transparent', color:color}}><span>PARC EURASANTE<br/>
             260 rue Salvador Allende<br/>
             Bâtiment B<br/>
             59120 LOOS<br/>
             FRANCE</span></Col>
             <Col style={{fontSize: 17,borderWidth:0, backgroundColor:'transparent', color:color, lineHeight: 1.8}}><a style={{fontSize: 17,borderWidth:0, backgroundColor:'transparent', color:color}} href='mailto:contact@healthsquad.fr'>contact@healthsquad.fr</a></Col>
             <Col style={{fontSize: 17,borderWidth:0, backgroundColor:'transparent', color:color, lineHeight: 1.8}}>07 69 11 46 02</Col>
            <Col style={{fontSize:30,display:'flex',borderWidth:0, backgroundColor:'transparent', color:color}}>
          <FaLinkedin onClick={() =>{window.open("https://www.linkedin.com/company/health-squad-france/?viewAsMember=true", '_blank')}} style={{cursor:'pointer',marginRight:20}}/>
          <FaTiktok onClick={() =>{window.open("https://www.tiktok.com/@healthsquadfrance/", '_blank')}} style={{cursor:'pointer',marginRight:20}} />
          <BsYoutube onClick={() =>{window.open("https://www.youtube.com/@HealthSquad-kh8sq", '_blank')}} style={{cursor:'pointer'}}/>
          </Col>
        </Col>
        </Col>
        <Col className='text-start text-md-end'>
        <Col style={{ paddingBottom:60}}>
        <Col><span style={{fontWeight:'bold',fontSize: 20,borderWidth:0, backgroundColor:'transparent', color:color, lineHeight: 1.8}}>{t("footer3")}</span><span style={{fontSize: 20,borderWidth:0, backgroundColor:'transparent', color:color, lineHeight: 1.8}}>{t("footer4")}</span></Col>
        <Col><span style={{fontSize: 20,borderWidth:0, backgroundColor:'transparent', color:color, lineHeight: 1.8}}>{t("footer5")}</span></Col>
        <Col style={{fontWeight:'bold',borderWidth:0,fontSize: 20, backgroundColor:'transparent', color:color, paddingTop:20, paddingBottom:20}}><Image src={logoUseconcept} style={{height:55}} fluid alt="logoUseconcept"/></Col>
        <Col style={{fontWeight:'bold',borderWidth:0,fontSize: 20, backgroundColor:'transparent', color:color, marginRight: -25, paddingBottom:20}}><Image src={logo} style={{height:55}} fluid alt="logo_HealthSquad"/></Col>
        </Col>
        </Col>
      
      </Row>
      </Col>
       
        </Row> 
         <Row className="justify-content-md-center" style={{paddingTop:20, backgroundColor:'rgba(59, 185, 163, 1)'}}>
        <Col className="text-center" style={{borderWidth:0, backgroundColor:'transparent', color:color, paddingBottom:20}}><NavLink className={isActive} style={{color:'white', textDecorationLine:'inherit', fontWeight:'400'}} to='/CGU'>{t("footer1")}</NavLink></Col>
        <Col className="text-center" style={{borderWidth:0, backgroundColor:'transparent', color:color, paddingBottom:20}}><NavLink className={isActive} style={{color:'white', textDecorationLine:'inherit', fontWeight:'400'}} to='/protection-des-donnees-personnelles'>{t("footer2")}</NavLink></Col>
        </Row>
        </Container>
        )
    }


export default Footer
