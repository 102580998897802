import React, {useState, useEffect} from 'react';
import { BsTelephone } from "react-icons/bs";
import { FiMail, FiMapPin } from "react-icons/fi";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import {Translations} from '../src/constants/i18n';
import ModalFin from './components/ModalFin';
import mailchimpFactory from '@mailchimp/mailchimp_transactional/src/index.js';
const mailchimp = mailchimpFactory("3MaihbLvVMRazJCeofwVyw");


const Contact = ({translation}) => {

  const [Nom, setNom] = useState('');
  const [Mail, setMail] = useState('');
  const [msg, setMsg] = useState('');
  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [color, setcolor] = useState('#EBC503');
  const [disabled, setdisabled] = useState(false);
  const contact1 = 'maxime.aldegheri@useradventure.fr'
  const contact2 = 'maxime.aldegheri@healthsquad.fr'
  const [showModal, setshowModal] = useState(false);


  useEffect(()=>{
    window.scrollTo(0, 0)
    }, [])


    const t = (value) => {
      return Translations[0][translation][value];
     }
  

  const ChangeNom = (event) => {
      setNom(event)
      if(event !== '' && Mail !=='' && msg !==''){
        setdisabledcolor(color)
        setdisabled(true)
      } else {
        setdisabledcolor('#DCDCDC')
        setdisabled(false)
      }
     console.log(event)
    };
    
    

    const ChangeMail = (event) => {
      setMail(event)
      if(event !== '' && Nom !=='' && msg !==''){
        setdisabledcolor(color)
        setdisabled(true)
      } else {
        setdisabledcolor('#DCDCDC')
        setdisabled(false)
      }
     console.log(event)
    };

  

    const ChangeMsg = (event) => {
      setMsg(event)
      if(event !== '' && Nom !=='' && Mail !==''){
        setdisabledcolor(color)
        setdisabled(true)
      } else {
        setdisabledcolor('#DCDCDC')
        setdisabled(false)
      }
     console.log(event)
    };


    const run = async (message) => {
      const response = await mailchimp.messages.sendTemplate({
        template_name: "HSContact",
        "template_content": [{}],
        message: message,
      });
      console.log(response);
      setNom('')
      setMsg('')
      setMail('')
      setshowModal(true)
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    };

    const validCommand1 = () => {
      var position = Mail.indexOf("@");
      var reste = Mail.substr(-position);
      var posit = reste.indexOf(".");
      //alert(posit + position + Mail )
      if(position !== -1 && posit !== -1 ){
      if(disabled){
        const message = {
          from_name: "HealthSquadContact",
          from_email: "contact@healthsquad.fr",
          subject: `Contact HealthSquad`,
          to: [
            {
              email: contact1,
              type: "to"
            },
            {
              email: contact2,
              type: "cc"
            }
          ],
          "merge_vars": [
            {
                "rcpt": contact1,
                "vars": [
                  {
                    "name": "EMAILPRO",
                    "content": Mail
                  },
                  {
                    "name": "TYPE",
                    "content": "Contact"
                  },
                  {
                    "name": "MOD",
                    "content": msg
                  },
                  {
                    "name": "NOM",
                    "content": Nom
                  },
                ]
            },
            {
              "rcpt": contact2,
              "vars": [
                {
                  "name": "EMAILPRO",
                  "content": Mail
                },
                {
                  "name": "TYPE",
                  "content": "Contact"
                },
                {
                  "name": "MOD",
                  "content": msg
                },
                {
                  "name": "NOM",
                  "content": Nom
                },
              ]
          }
        ]
        }
      run(message) 
        }else{
          alert('Veuillez remplir tous les champs, Merci!')
        }
        }else{
      alert('Email non valide')
    }
  }

  return (
      <div>
      <Container fluid style={{background: 'rgba(249, 253, 252, 1)', paddingBottom : 120 ,paddingTop:200}}>

      <Row style={{justifyContent:'center',alignItems:'flex-start',paddingInline:30}}>
       
      <Col xl={{ order: 'first', span:4 }} xs={{ order: 'last' }}  style={{paddingInline:30, paddingBlock:0}}>
      
            <Col style={{backgroundColor:''}}>
            
            <Col  style={{color:'#1A2B6E', fontSize:27, fontFamily:'Poppins, sans-serif',fontWeight: '700' ,lineHeight:1.4,marginBottom:20}}>{t("contact0")}</Col>
            <Col  style={{color:'#1A2B6E', fontSize:17, fontFamily:'Poppins, sans-serif',fontWeight: '400' ,lineHeight:1.4}}>{t("contact1")}</Col>
            <Col  style={{color:'#1A2B6E', fontSize:17, fontFamily:'Poppins, sans-serif',fontWeight: '400' ,lineHeight:1.4,marginBottom:20}}>{t("contact9")}</Col>
              <Col style={{color:'#1A2B6E', fontSize:17, fontFamily:'Poppins, sans-serif',fontWeight: '400' ,lineHeight:1.4,marginBottom:10}}><BsTelephone style={{color:'rgba(59, 185, 163, 1)', fontSize:20,marginRight:10}}/>07 69 11 46 02</Col>
              <Col style={{color:'#1A2B6E', fontSize:17, fontFamily:'Poppins, sans-serif',fontWeight: '400' ,lineHeight:1.4,marginBottom:10}}><FiMail  style={{color:'rgba(59, 185, 163, 1)', fontSize:20,marginRight:10}}/><a style={{fontSize: 17,borderWidth:0, backgroundColor:'transparent', color:'#4d7abe'}} href='mailto:contact@healthsquad.fr'>contact@healthsquad.fr</a></Col>
              
              
              
              
              <Col  style={{color:'#1A2B6E', fontSize:27, fontFamily:'Poppins, sans-serif',fontWeight: '700' ,lineHeight:1.4 ,paddingTop:100,marginBottom:10}}>{t("contact0")}</Col>
              <Col style={{marginBottom:10}}><FiMapPin style={{color:'rgba(59, 185, 163, 1)', fontSize:20,marginRight:10,marginBottom:10}}/><span style={{color: '#3BB9A3', fontSize:26, fontWeight: 700, fontFamily:'Poppins, sans-serif'}}>Med</span><span style={{color: '#1A2B6E', fontSize:26, fontWeight: 700, fontFamily:'Poppins, sans-serif'}}>Lab</span><span style={{color: '#1A2B6E', fontSize:26, fontWeight: 400, fontFamily:'Poppins, sans-serif',marginRight:0}}>Lille</span></Col>
              <Col  style={{color:'#1A2B6E', fontSize:17, fontFamily:'Poppins, sans-serif',fontWeight: '400' ,lineHeight:1.4}}>260 rue Salvador Allende</Col>
              <Col  style={{color:'#1A2B6E', fontSize:17, fontFamily:'Poppins, sans-serif',fontWeight: '400' ,lineHeight:1.4,marginBottom:20}}>59120 LOOS</Col>
              <Col  style={{color:'#1A2B6E', fontSize:17, fontFamily:'Poppins, sans-serif',fontWeight: '400' ,lineHeight:1.4}}>{t("contact10")}</Col>
              <Col  style={{color:'#1A2B6E', fontSize:17, fontFamily:'Poppins, sans-serif',fontWeight: '400' ,lineHeight:1.4}}>{t("contact11")}</Col>
              <Col  style={{color:'#1A2B6E', fontSize:17, fontFamily:'Poppins, sans-serif',fontWeight: '400' ,lineHeight:1.4}}>{t("contact12")}</Col>
            </Col>
           
            
      </Col>
      
      <Col xl={{ order: 'last', span:4 }} xs={{ order: 'first' }} style={{paddingInline:30, paddingBlock:30, borderRadius:15, boxShadow:'0px 0px 15px rgba(26, 43, 110, 0.4)'}} >
      <Col  style={{color:'#1A2B6E', fontSize:17, fontFamily:'Poppins, sans-serif',fontWeight: '600' ,lineHeight:1.4}}>{t("contact6")}</Col>
      <Col  style={{color:'#1A2B6E', fontSize:17, fontFamily:'Poppins, sans-serif',fontWeight: '600' ,marginBottom:20,lineHeight:1.4}}>{t("contact7")}</Col>
      <div className="form-floating mb-3">
                              <input type="text" value={Nom} onChange={e =>ChangeNom(e.target.value)} style={{borderRadius:10}} className="form-control" id="floatingInput" placeholder="name@example.com"/>
                              <label>{t("contact2")}</label>
                          </div>
                         
                          <div className="form-floating mb-3">
                              <input type="email" value={Mail} onChange={e =>ChangeMail(e.target.value)} style={{borderRadius:10}} className="form-control" id="floatingPrenom" placeholder="name@example.com"/>
                              <label>{t("contact3")}</label>
                          </div>

                          <Col  style={{color:'#1A2B6E', fontSize:17, fontFamily:'Poppins, sans-serif',fontWeight: '600' ,marginTop:20,lineHeight:1.4}}>{t("contact8")}</Col>

                          <div className="form-floating mb-3">
                          <Form.Select aria-label="Default select example" style={{borderRadius:10, paddingBlock:5}} onChange={e =>console.log(e.target.value)}>
                            <option>{t("labelcontact0")}</option>
                            <option value="1">{t("labelcontact1")}</option>
                            <option value="2">{t("labelcontact2")}</option>
                            <option value="3">{t("labelcontact3")}</option>
                            <option value="4">{t("labelcontact4")}</option>
                            <option value="5">{t("labelcontact5")}</option>
                          </Form.Select>
                          </div>                      
                         
                          <div className="form-floating mb-3">
                              <textarea  rows="5" type="Prénom" value={msg} onChange={e =>ChangeMsg(e.target.value)} style={{borderRadius:10, height:150}} className="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                              <label>{t("contact4")}</label>
                          </div>  
  <div className="text-end">
  <Button variant="outline-light" onClick={()=> validCommand1()}  style={{borderWidth:0, background: disabledcolor, borderRadius:50, paddingInline:45, paddingBlock:10, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}>{t("contact5")}</Button>
  </div>
      </Col>
</Row>
     <ModalFin show={showModal} Quit={() => {setshowModal(false)}} />
      </Container>
     
      </div>
  )
}

export default Contact