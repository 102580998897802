import React, {useState, useEffect} from 'react';
import './App.css';
import { Container, Row, Col, Image, Button, Accordion } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import logo_diabeloop from './images/logo_diabeloop.png';
import logo_fillmed from './images/logo_fillmed.png';
import logo_carmat from './images/logo_carmat.png';
import logo_BD from './images/logo_BD.png';
import approuve from './images/u67_selected.svg';
import clef from './images/u60.png';
import planning from './images/u61.png';
import chrono from './images/chrono.png';
import couverture from './images/u3.avif';
import couverture1 from './images/u3.avif';
import salle from './images/u47.avif';
import salle2 from './images/u51.avif';
import salle3 from './images/u43.avif';
import salle4 from './images/u55.avif';
import salle5 from './images/u56.avif';
import salle6 from './images/u631.avif';
import salle7 from './images/u67.avif';
import salle8 from './images/u71.avif';
import salle9 from './images/u75.avif';
import u25 from './images/u25.png';
import u26 from './images/u26.png';
import u144 from './images/u144.png';
import u145 from './images/u145.png';
import u222 from './images/u222.avif';
import u239 from './images/u239.svg';
import u192 from './images/u192.svg';
import u218 from './images/u218.svg';
import u212 from './images/u212.svg';
import u226 from './images/u226.svg';
import u228 from './images/u228.svg';
import u230 from './images/u230.svg';
import u232 from './images/u232.svg';
import "react-datepicker/dist/react-datepicker.css";
import {useWindowDimensions} from 'react-native';
import {Translations} from '../src/constants/i18n';

const Home = ({translation}) => {

const [DataEtude, setDataEtude] = useState([]);
const { height, width } = useWindowDimensions();
const [isFixed, setIsFixed] = useState(false);
const [isScrollTop, setScrollTop] = useState(false);
console.log('homeT',translation)
const [step, setStep] = useState(1);
const URL = 'https://www.healthsquad.fr/request/'

const t = (value) => {
  return Translations[0][translation][value];
 }

useEffect(()=>{
 
 
  }, [])

  const handleDragStart = (e) => e.preventDefault();

  return (
    <>
    <div id="top"></div>
    <Container  style={{ paddingBottom:0, paddingTop:90}} fluid>
    <Row style={{justifyContent:'center',alignItems:'center', background: '#f0f0f8'}}>
        
            <Col xs={{ order: 'last' }} lg={{ order: 'first', span:5 }} style={{paddingInline:30, paddingBlock:30}}>
            
                  <Col lg={{span: 10, offset: 1 }} xxl={{span: 10, offset: 1 }} className="text-lg-start text-center" style={{backgroundColor:''}}>
                  <Col  className="text-start" style={{backgroundColor:''}}>
                   {/* <Image src={doodle1} style={{ marginLeft: width < 768 ? 0 : -50,height:50}} alt='Recrutement pro de santé loi anti-cadeaux' /> */}
                   </Col>
                    <h1  style={{color:'#1A2B6E', fontSize:width > 1440 ? 52:40,fontWeight: '700', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1}}>{t("home1")}</h1>
                    <h1  style={{color:`#3BB9A3`, fontSize:width > 1440 ? 52:40,fontWeight: '600', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1}}>{t("home2")} {t("home3")}</h1>
                    <h3  style={{color:'#4f6382', fontSize:width > 1440 ? 22:16,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.4}}>{t("home4")}</h3>
                 
                 
                 <Row style={{justifyContent:'center',alignItems:'center'}}>
        <Col>
        <Col style={{marginBlock:50}}>
        <Col className="text-start" style={{color:'#4f6382', fontSize:16,fontWeight: '600', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:0, height:22, width:22, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("home5")}</Col>
        <Col className="text-start" style={{color:'#4f6382', fontSize:16,fontWeight: '600', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:0, height:22, width:22, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("home6")}</Col>
        <Col className="text-start" style={{color:'#4f6382', fontSize:16,fontWeight: '600', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:0, height:22, width:22, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("home7")}</Col>
        </Col> 
        <Button variant="outline-light" href="/contact"  style={{borderWidth:0, background:  `#3BB9A3`, borderRadius:30, paddingInline:30, paddingBlock:15, fontSize:17,fontWeight: '600', fontFamily:'Poppins, sans-serif'}}>{t("btn2")}</Button>
   
              </Col>  
      </Row>
     
                  </Col>
                  <Col  className="text-end" style={{backgroundColor:''}}>
                   {/* <Image src={doodle2} style={{ height:50}} alt='Recrutement pro de santé loi anti-cadeaux'/> */}
                   </Col>
                   
            </Col>
            
            <Col xs={{ order: 'first' }} lg={{ order: 'last' , span:7}}  >

            {/* <Image src={couverture} fluid /> */}
            
            <div style={{ alignContent:'flex-end', paddingBottom: width > 1440 ? 80:0,
      backgroundImage: `url(${width < 1000 ? couverture1 : couverture})`, 
      backgroundPosition: 'center', 
      backgroundSize: 'cover', 
       backgroundColor: 'rgba(5, 28, 108, 0.5)',
       backgroundBlendMode:'Overlay',
      width: 'auto', 
      height: width < 1000 ? '60vw' :'40vw' 
    }}
    alt='Recrutement pro de santé loi anti-cadeaux'
    >

<Row  className="justify-content-center"  style={{ paddingBlock:0}}>
           <Col xs={5} lg="2" style={{marginTop:70, marginBottom: 20, padding:0, borderRadius:3, marginInline:10}}>
              <Col  style={{color: 'white',fontSize:width > 1440 ? 34:24, fontWeight: 600, fontFamily:'Poppins, sans-serif', paddingBottom:0}} >
              {t("home8")}
              </Col>  
              <Col style={{color: 'white', fontSize:width > 1440 ? 14:12, fontWeight: 500, fontFamily:'Poppins, sans-serif'}}>
              {t("home9")}
              </Col> 
              
            </Col>  
            <Col xs={5} lg="2" style={{marginTop:70, marginBottom: 20, padding:0, borderRadius:3, marginInline:10}}>
              
              <Col  style={{color: 'white',fontSize:width > 1440 ? 34:24, fontWeight: 600, fontFamily:'Poppins, sans-serif', paddingBottom:0}} >
              {t("home10")}
              </Col>  
              <Col xs={12} style={{color: 'white', fontSize:width > 1440 ? 14:12, fontWeight: 500, fontFamily:'Poppins, sans-serif'}}>
              {t("home11")}
              </Col> 
              
            </Col>  
            <Col xs={10} lg="2" style={{marginTop:70 , marginBottom: 20, padding:0, borderRadius:3, marginInline:10,display: width > 1440 ? 'flex':'none'}}>
              <Col className="text-end" xs={12}  style={{color: '#AEBFFD', fontSize:13, fontWeight: 500 , fontFamily:'Poppins, sans-serif'}} >
              {t("home12")}<br/>
              {t("home13")}<br/>
              {t("home14")}<br/>
              {t("home15")}<br/>
              {t("home16")}<br/>
              {t("home17")}
              </Col> 
            </Col>  
            <Col xs={10}  lg="2" style={{marginTop:70, marginBottom: 20, padding:0, borderRadius:3, marginInline:10,display: width > 1440 ? 'flex':'none'}}>
              <Col className="text-end" xs={12}  style={{color: '#AEBFFD', fontSize:13, fontWeight: 500 , fontFamily:'Poppins, sans-serif'}} >
              {t("home18")}<br/>
              {t("home19")}<br/>
              {t("home20")}<br/>
              {t("home21")}<br/>
              {t("home22")}<br/>
              {t("home23")}
              </Col> 
            </Col>  
            
            </Row>
     
    </div>
            </Col>
      </Row>
     
      <Row  className="justify-content-md-center"  style={{ paddingBlock:20, backgroundColor:'rgba(188, 239, 227, 1)',marginTop:width > 1440 ? 0:70}}>
           <Col xs={10}  onClick={()=> setStep(1)} lg="3" style={{ backgroundColor:'rgba(59, 185, 163, 1)', marginTop:-70, marginBottom: 40, padding:40, borderRadius:0, marginInline:10}}>
           <Image src={clef} style={{height:40, width:40, marginBottom:15 }} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>
              <Col  style={{color: 'white',fontSize:20, fontWeight: 700, fontFamily:'Poppins, sans-serif', paddingBottom:15}} >
              {t("home0-1")}
              </Col>  
              <Col xs={12} style={{color: 'white', fontSize:17, fontWeight: 400, fontFamily:'Poppins, sans-serif'}}  className="my-auto">
              {t("home0-2")}
              </Col> 
              
            </Col>  
            <Col xs={10}  onClick={()=> setStep(2)} lg="3" style={{ backgroundColor:'rgba(59, 185, 163, 1)', marginTop:-70, marginBottom: 40, padding:40, borderRadius:0, marginInline:10}}>
              <Image src={planning} style={{height:40, width:40, marginBottom:15 }} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>
              <Col  style={{color: 'white',fontSize:20, fontWeight: 700, fontFamily:'Poppins, sans-serif', paddingBottom:15}} >
              {t("home0-3")}
              </Col>  
              <Col xs={12} style={{color: 'white', fontSize:17, fontWeight: 400, fontFamily:'Poppins, sans-serif'}} className="my-auto">
              {t("home0-4")}
              </Col> 
              
            </Col>  
            <Col xs={10} c onClick={()=> setStep(3)} lg="3" style={{ backgroundColor:'rgba(59, 185, 163, 1)', marginTop:-70, marginBottom: 40, padding:40, borderRadius:0, marginInline:10}}>
              <Image src={chrono} style={{height:40, width:40, marginBottom:15 }} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>
              <Col style={{color: 'white',fontSize:20, fontWeight: 700, fontFamily:'Poppins, sans-serif', paddingBottom:15}} >
              {t("home0-5")}
              </Col>  
              <Col xs={12}  style={{color: 'white', fontSize:17, fontWeight: 400 , fontFamily:'Poppins, sans-serif'}} className="my-auto">
              {t("home0-6")}
              </Col> 
               <span id="space"></span>
            </Col>  
          
            </Row>


{/* //---------------------LAB SALLE------------------------- */}
{/* //---------------------LAB SALLE------------------------- */}
{/* //---------------------LAB SALLE------------------------- */}

            <Row  style={{justifyContent:'center',alignItems:'center', padding:width > 1440 ? 60:20}}>
        <Col  xs={{ order: 'first' }} lg={{ order: 'last', span:5 }} style={{paddingInline:0, paddingBlock:30}}>
              <Col xxl={{span: 10, offset: 1 }} className="text-lg-start text-center" style={{backgroundColor:''}}>
              <Col className="text-start" style={{ fontFamily:'Poppins, sans-serif',marginBottom:20}}><span style={{color:`#3BB9A3`, fontSize:24,fontWeight: '400', fontFamily:'Poppins, sans-serif' }}>{t("lab0")}</span><span style={{color:`#1A2B6E`, fontSize:24,fontWeight: '700', fontFamily:'Poppins, sans-serif' }}>{t("lab1")}</span></Col>
                <Col className="text-start" style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.7}}>{t("lab2")}</Col>
                <Row className="text-start" style={{justifyContent:'center'}}>
                <Col>
                <ul>
                  <li style={{color:`#1A2B6E`}}><span style={{color:`#1A2B6E`, fontSize:14,fontWeight: '600', fontFamily:'Poppins, sans-serif' }}>{t("lab3")}</span></li>
                  <li style={{color:`#1A2B6E`}}><span style={{color:`#1A2B6E`, fontSize:14,fontWeight: '600', fontFamily:'Poppins, sans-serif' }}>{t("lab4")}</span></li>
                  <li style={{color:`#1A2B6E`}}><span style={{color:`#1A2B6E`, fontSize:14,fontWeight: '600', fontFamily:'Poppins, sans-serif' }}>{t("lab5")}</span></li>
                </ul>
                </Col>
                <Col>
                <ul>
                  <li style={{color:`#1A2B6E`}}><span style={{color:`#1A2B6E`, fontSize:14,fontWeight: '600', fontFamily:'Poppins, sans-serif' }}>{t("lab6")}</span></li>
                  <li style={{color:`#1A2B6E`}}><span style={{color:`#1A2B6E`, fontSize:14,fontWeight: '600', fontFamily:'Poppins, sans-serif' }}>{t("lab7")}</span></li>
                  <li style={{color:`#1A2B6E`}}><span style={{color:`#1A2B6E`, fontSize:14,fontWeight: '600', fontFamily:'Poppins, sans-serif' }}>{t("lab8")}</span></li>
                </ul>
                </Col>
                </Row>
                <Col className="text-start" style={{color:'#4f6382', fontSize:14,fontWeight: '600', fontFamily:'Poppins, sans-serif' ,marginBlock:10}}>{t("lab9")}</Col>
                <Row  style={{justifyContent:'center', marginBottom:50}}>
                <Col className="text-start">
                <ul>
                  <li style={{color:`#1A2B6E`}}><span style={{color:`#1A2B6E`, fontSize:14,fontWeight: '400', fontFamily:'Poppins, sans-serif' }}>{t("lab10")}</span></li>
                  <li style={{color:`#1A2B6E`}}><span style={{color:`#1A2B6E`, fontSize:14,fontWeight: '400', fontFamily:'Poppins, sans-serif' }}>{t("lab11")}</span></li>
                  <li style={{color:`#1A2B6E`}}><span style={{color:`#1A2B6E`, fontSize:14,fontWeight: '400', fontFamily:'Poppins, sans-serif' }}>{t("lab12")}</span></li>
                  <li style={{color:`#1A2B6E`}}><span style={{color:`#1A2B6E`, fontSize:14,fontWeight: '400', fontFamily:'Poppins, sans-serif' }}>{t("lab15")}</span></li>
                  <li style={{color:`#1A2B6E`}}><span style={{color:`#1A2B6E`, fontSize:14,fontWeight: '400', fontFamily:'Poppins, sans-serif' }}>{t("lab13")}</span></li>
                  <li style={{color:`#1A2B6E`}}><span style={{color:`#1A2B6E`, fontSize:14,fontWeight: '400', fontFamily:'Poppins, sans-serif' }}>{t("lab14")}</span></li>
                </ul>
                </Col>
                
                </Row>
             <Row style={{justifyContent:'center',alignItems:'center'}}>
    <Col style={{marginTop:0}}>
    <div>
    <Button variant="outline-light" href="/contact"  style={{borderWidth:0, background:  `rgba(235, 197, 3, 1)`, borderRadius:30, paddingInline:40, paddingBlock:15, fontSize:17,fontWeight: '600', fontFamily:'Poppins, sans-serif'}}>{t("btn4")}</Button>
    </div>
          </Col>  
  </Row>
 
              </Col>
              
               
        </Col>
        
        
        <Col  xs={{ order: 'last' }} lg={{ order: 'first' , span:5}}  >
        <Carousel>
        <Carousel.Item>
  <div class="card"  style={{ borderRadius:15, marginBottom:20}}>
 <img src={salle3} style={{ borderRadius:15, position:'relative'}} class="card-img-top" alt="Test utilisateur" onDragStart={handleDragStart} role="presentation"/>
 <div style={{  backgroundColor: 'rgba(5, 28, 108, 0.5)',
       backgroundBlendMode:'Overlay',position:'absolute',bottom:0,borderBottomRightRadius:15,borderBottomLeftRadius:15,padding:15}}>
 <div  class="row align-items-start">
   <p class="card-title" style={{color: 'white', fontSize:  width > 1440 ? 16:12, fontWeight:'bold', fontFamily:'Poppins, sans-serif'}}>{t("salle0-0")}</p>
   <p class="card-text" style={{color: 'white', fontSize:  width > 1440 ? 14:10, fontFamily:'Poppins, sans-serif'}}>{t("salle0-1")}</p>
   </div>
 </div>
</div> 
</Carousel.Item>
       <Carousel.Item>
  <div class="card"  style={{ borderRadius:15, marginBottom:20}}>
 <img src={salle} style={{ borderRadius:15, position:'relative'}} class="card-img-top" alt="Test utilisateur" onDragStart={handleDragStart} role="presentation"/>
 <div style={{  backgroundColor: 'rgba(5, 28, 108, 0.5)',
       backgroundBlendMode:'Overlay',position:'absolute',bottom:0,borderBottomRightRadius:15,borderBottomLeftRadius:15,padding:15}}>
 <div  class="row align-items-start">
   <p class="card-title" style={{color: 'white', fontSize:  width > 1440 ? 16:12, fontWeight:'bold', fontFamily:'Poppins, sans-serif'}}>{t("salle1-0")}</p>
   <p class="card-text" style={{color: 'white', fontSize:  width > 1440 ? 14:10, fontFamily:'Poppins, sans-serif'}}>{t("salle1-1")}</p>
   </div>
 </div>
</div> 
</Carousel.Item>
<Carousel.Item>
  <div class="card"  style={{ borderRadius:15, marginBottom:20}}>
 <img src={salle2} style={{ borderRadius:15, position:'relative'}} class="card-img-top" alt="Test utilisateur" onDragStart={handleDragStart} role="presentation"/>
 <div style={{  backgroundColor: 'rgba(5, 28, 108, 0.5)',
       backgroundBlendMode:'Overlay',position:'absolute',bottom:0,borderBottomRightRadius:15,borderBottomLeftRadius:15,padding:15}}>
 <div  class="row align-items-start">
   <p class="card-title" style={{color: 'white', fontSize:  width > 1440 ? 16:12, fontWeight:'bold', fontFamily:'Poppins, sans-serif'}}>{t("salle2-0")}</p>
   <p class="card-text" style={{color: 'white', fontSize:  width > 1440 ? 14:10, fontFamily:'Poppins, sans-serif'}}>{t("salle2-1")}</p>
   </div>
 </div>
</div> 
</Carousel.Item>

<Carousel.Item>
  <div class="card"  style={{ borderRadius:15, marginBottom:20}}>
 <img src={salle4} style={{ borderRadius:15, position:'relative'}} class="card-img-top" alt="Test utilisateur" onDragStart={handleDragStart} role="presentation"/>
 <div style={{  backgroundColor: 'rgba(5, 28, 108, 0.5)',
       backgroundBlendMode:'Overlay',position:'absolute',bottom:0,borderBottomRightRadius:15,borderBottomLeftRadius:15,padding:15}}>
 <div  class="row align-items-start">
   <p class="card-title" style={{color: 'white', fontSize:  width > 1440 ? 16:12, fontWeight:'bold', fontFamily:'Poppins, sans-serif'}}>{t("salle3-0")}</p>
   <p class="card-text" style={{color: 'white', fontSize:  width > 1440 ? 14:10, fontFamily:'Poppins, sans-serif'}}>{t("salle3-1")}</p>
   </div>
 </div>
</div> 
</Carousel.Item>
<Carousel.Item>
  <div class="card"  style={{ borderRadius:15, marginBottom:20}}>
 <img src={salle5} style={{ borderRadius:15, position:'relative'}} class="card-img-top" alt="Test utilisateur" onDragStart={handleDragStart} role="presentation"/>
 <div style={{  backgroundColor: 'rgba(5, 28, 108, 0.5)',
       backgroundBlendMode:'Overlay',position:'absolute',bottom:0,borderBottomRightRadius:15,borderBottomLeftRadius:15,padding:15}}>
 <div  class="row align-items-start">
   <p class="card-title" style={{color: 'white', fontSize:  width > 1440 ? 16:12, fontWeight:'bold', fontFamily:'Poppins, sans-serif'}}>{t("salle4-0")}</p>
   <p class="card-text" style={{color: 'white', fontSize:  width > 1440 ? 14:10, fontFamily:'Poppins, sans-serif'}}>{t("salle4-1")}</p>
   </div>
 </div>
</div> 
</Carousel.Item>
<Carousel.Item>
  <div class="card"  style={{ borderRadius:15, marginBottom:20}}>
 <img src={salle6} style={{ borderRadius:15, position:'relative'}} class="card-img-top" alt="Test utilisateur" onDragStart={handleDragStart} role="presentation"/>
 <div style={{  backgroundColor: 'rgba(5, 28, 108, 0.5)',
       backgroundBlendMode:'Overlay',position:'absolute',bottom:0,borderBottomRightRadius:15,borderBottomLeftRadius:15,padding:15}}>
 <div  class="row align-items-start">
   <p class="card-title" style={{color: 'white', fontSize:  width > 1440 ? 16:12, fontWeight:'bold', fontFamily:'Poppins, sans-serif'}}>{t("salle5-0")}</p>
   <p class="card-text" style={{color: 'white', fontSize:  width > 1440 ? 14:10, fontFamily:'Poppins, sans-serif'}}>{t("salle5-1")}</p>
   </div>
 </div>
</div> 
</Carousel.Item>
<Carousel.Item>
  <div class="card"  style={{ borderRadius:15, marginBottom:20}}>
 <img src={salle7} style={{ borderRadius:15, position:'relative'}} class="card-img-top" alt="Test utilisateur" onDragStart={handleDragStart} role="presentation"/>
 <div style={{  backgroundColor: 'rgba(5, 28, 108, 0.5)',
       backgroundBlendMode:'Overlay',position:'absolute',bottom:0,borderBottomRightRadius:15,borderBottomLeftRadius:15,padding:15}}>
 <div  class="row align-items-start">
   <p class="card-title" style={{color: 'white', fontSize:  width > 1440 ? 16:12, fontWeight:'bold', fontFamily:'Poppins, sans-serif'}}>{t("salle6-0")}</p>
   <p class="card-text" style={{color: 'white', fontSize:  width > 1440 ? 14:10, fontFamily:'Poppins, sans-serif'}}>{t("salle6-1")}</p>
   </div>
 </div>
</div> 
</Carousel.Item>
<Carousel.Item>
  <div class="card"  style={{ borderRadius:15, marginBottom:20}}>
 <img src={salle8} style={{ borderRadius:15, position:'relative'}} class="card-img-top" alt="Test utilisateur" onDragStart={handleDragStart} role="presentation"/>
 <div style={{  backgroundColor: 'rgba(5, 28, 108, 0.5)',
       backgroundBlendMode:'Overlay',position:'absolute',bottom:0,borderBottomRightRadius:15,borderBottomLeftRadius:15,padding:15}}>
 <div  class="row align-items-start">
   <p class="card-title" style={{color: 'white', fontSize:  width > 1440 ? 16:12, fontWeight:'bold', fontFamily:'Poppins, sans-serif'}}>{t("salle7-0")}</p>
   <p class="card-text" style={{color: 'white', fontSize:  width > 1440 ? 14:10, fontFamily:'Poppins, sans-serif'}}>{t("salle7-1")}</p>
   </div>
 </div>
</div> 
</Carousel.Item>
<Carousel.Item>
  <div class="card"  style={{ borderRadius:15, marginBottom:20}}>
 <img src={salle9} style={{ borderRadius:15, position:'relative'}} class="card-img-top" alt="Test utilisateur" onDragStart={handleDragStart} role="presentation"/>
 <div style={{  backgroundColor: 'rgba(5, 28, 108, 0.5)',
       backgroundBlendMode:'Overlay',position:'absolute',bottom:0,borderBottomRightRadius:15,borderBottomLeftRadius:15,padding:15}}>
 <div  class="row align-items-start">
   <p class="card-title" style={{color: 'white', fontSize:  width > 1440 ? 16:12, fontWeight:'bold', fontFamily:'Poppins, sans-serif'}}>{t("salle8-0")}</p>
   <p class="card-text" style={{color: 'white', fontSize:  width > 1440 ? 14:10, fontFamily:'Poppins, sans-serif'}}>{t("salle8-1")}</p>
   </div>
 </div>
</div> 
</Carousel.Item>
   </Carousel> 
       
        </Col>
  </Row>
  
  <Col   lg={{span: 4, offset: 4 }} style={{ marginTop:80 , paddingBottom: 0}}>
        <Col className="text-center" style={{margin:30}}><span style={{color: '#1A2B6E', fontSize:24, fontWeight: 700, fontFamily:'Poppins, sans-serif'}}>{t("confiance1")}</span><span style={{color: '#3BB9A3', fontSize:24, fontWeight: 500, fontFamily:'Poppins, sans-serif'}}>{t("confiance2")}</span><span style={{color: '#1A2B6E', fontSize:24, fontWeight: 700, fontFamily:'Poppins, sans-serif'}}>{t("confiance3")}</span></Col>
       
      </Col>  


  <Row style={{ paddingBottom: 80}}>
  <Col   lg={{span: 6, offset: 3 }} style={{  paddingBottom: 60}}>
        <Carousel data-bs-theme="dark" variant="dark">
       <Carousel.Item>
 <div class="card" style={{  backgroundColor: 'rgba(231, 251, 249, 1)',borderRadius:15,paddingBlock:40, paddingInline:width > 1440 ? 100:0}}>
 <Row>
              <Col xs="4" lg="3" className="m-auto">
              <Image src={logo_BD} style={{ height:60}} alt='Diabeloop'/>
              </Col>  
              <Col xs="9" className="m-auto">
              <Col style={{paddingBottom:10}}><span style={{color: '#3BB9A3', fontSize:17, fontWeight: 600, fontFamily:'Poppins, sans-serif'}}>{t("verbatim1")}</span>  <span style={{color: '#7F7F7F', fontSize:17, fontWeight: 400, fontFamily:'Poppins, sans-serif'}}>{t("verbatim2")}</span></Col>
              <Col style={{color: '#1A2B6E', fontSize:12, fontWeight: 400, fontFamily:'Poppins, sans-serif',fontStyle:'italic'}}>"{t("verbatim3")}"</Col>
              </Col> 
              </Row>
 </div>
</Carousel.Item>
 
   </Carousel> 
   </Col>   
   </Row>


  
   <Row   xs={1} md={4} lg={10} style={{zIndex:-1,justifyContent:'center',alignItems:'center', backgroundImage: `url(${u222})`,  backgroundSize: 'cover', 
       backgroundColor: 'rgba(26, 43, 110, 0.8)',
       backgroundBlendMode:'Overlay', paddingBlock:80}}>
       
           <Col >
              <Col className="text-center text-md-start" style={{color: 'white', fontSize:40, fontWeight: 700, lineHeight:1, paddingBottom:15}}>
              {t("numbers1")}<span style={{color: '#3BB9A3', fontSize:40, fontWeight: 400}}>{t("numbers2")}</span>
              </Col>  
              <Col className="text-center text-md-start" style={{color: 'white', fontSize:17, fontWeight: 400, paddingBottom:65}}>
              {t("numbers3")}
              </Col> 
              <Col className="text-center text-md-start" style={{color: '#60697b', fontSize:17, fontWeight: 400}}>
                <Button variant="outline-light" href="/contact"  style={{borderWidth:0, background:  `#3BB9A3`, borderRadius:30, paddingInline:30, paddingBlock:15, fontSize:17,fontWeight: '600', fontFamily:'Poppins, sans-serif'}}>{t("btn2")}</Button>
              </Col> 
            </Col>  
            <Col style={{paddingInline:60,marginTop:width > 1440 ? 0:70}}>
            <Row  style={{marginBottom:20, height:50}}>
            <Col className="m-auto" xs="1" >
            <Col style={{height:25, width:25, background: '#3BB9A3', borderRadius:12, borderBottomLeftRadius:0}}></Col>
            </Col>
            <Col xs="11" className="m-auto" style={{color: 'white', fontSize:17, fontWeight: 400}}>
             {t("numbers4")}
              </Col>
             </Row> 
             <Row  style={{marginBottom:20, height:50}}>
            <Col className="m-auto" xs="1" >
            <Col style={{height:25, width:25, background: '#3BB9A3', borderRadius:12, borderBottomLeftRadius:0}}></Col>
            </Col>
            <Col xs="11" className="m-auto" style={{color: 'white', fontSize:17, fontWeight: 400}}>
              {t("numbers5")}
              </Col>
              </Row> 
              <Row  style={{marginBottom:20, height:50}}>
            <Col className="m-auto" xs="1" >
            <Col style={{height:25, width:25, background: '#3BB9A3', borderRadius:12, borderBottomLeftRadius:0}}></Col>
            </Col>
            <Col xs="11" className="m-auto" style={{color: 'white', fontSize:17, fontWeight: 400}}>
              {t("numbers6")}
              </Col> 
              </Row>
              <Row  style={{marginBottom:20, height:50}}>
            <Col className="m-auto" xs="1" >
            <Col style={{height:25, width:25, background: '#3BB9A3', borderRadius:12, borderBottomLeftRadius:0}}></Col>
            </Col>
            <Col xs="11" className="m-auto" style={{color: 'white', fontSize:17, fontWeight: 400}}>
              {t("numbers7")}
              </Col>
              </Row>
              
            </Col>  
            <Col style={{paddingInline:60}}>
            <Row  style={{marginBottom:20, height:50}}>
            <Col className="m-auto" xs="1" >
            <Col style={{height:25, width:25, background: '#3BB9A3', borderRadius:12, borderBottomLeftRadius:0}}></Col>
            </Col>
            <Col xs="11" className="m-auto" style={{color: 'white', fontSize:17, fontWeight: 400}}>
              {t("numbers8")}
              </Col>
             </Row> 
             <Row  style={{marginBottom:20, height:50}}>
            <Col className="m-auto" xs="1" >
            <Col style={{height:25, width:25, background: '#3BB9A3', borderRadius:12, borderBottomLeftRadius:0}}></Col>
            </Col>
            <Col xs="11" className="m-auto" style={{color: 'white', fontSize:17, fontWeight: 400}}>
              {t("numbers9")}
              </Col>
              </Row> 
              <Row  style={{marginBottom:20, height:50}}>
            <Col className="m-auto" xs="1" >
            <Col style={{height:25, width:25, background: '#3BB9A3', borderRadius:12, borderBottomLeftRadius:0}}></Col>
            </Col>
            <Col xs="11" className="m-auto" style={{color: 'white', fontSize:17, fontWeight: 400}}>
              {t("numbers10")}
              </Col> 
              </Row>
              <Row  style={{marginBottom:20, height:50}}>
            <Col className="m-auto" xs="1" >
            <Col style={{height:25, width:25, background: '#3BB9A3', borderRadius:12, borderBottomLeftRadius:0}}></Col>
            </Col>
            <Col xs="11" className="m-auto" style={{color: 'white', fontSize:17, fontWeight: 400}}>
              {t("numbers11")}
              </Col>
              </Row>
               <span id="panel"></span>
            </Col> 
            
      </Row>
     
      <Col lg={{span: 6, offset: 3 }} style={{ marginTop:70 , paddingBottom: 0}}>
        <Col className="text-center"><span style={{color: '#1A2B6E', fontSize:26, fontWeight: 700, fontFamily:'Poppins, sans-serif'}}>{t("profil1")}</span><span style={{color: '#3BB9A3', fontSize:26, fontWeight: 500, fontFamily:'Poppins, sans-serif'}}>{t("profil2")}</span><span style={{color: '#1A2B6E', fontSize:26, fontWeight: 700, fontFamily:'Poppins, sans-serif'}}>{t("profil3")}</span></Col>
        <Col className="text-center" style={{marginInline:0}}><span style={{color: '#626981', fontSize:16, fontWeight: 400, fontFamily:'Poppins, sans-serif'}}>{t("profil4")}</span></Col>
      </Col>
  

      <Row  className="justify-content-md-center"  style={{ justifyContent:'center',paddingBottom:40}}>
           <Col xs={10} lg="2" style={{ backgroundColor:'rgba(231, 251, 249, 1)', marginTop:70, marginBottom: 0, padding:40, borderRadius:3, marginInline:10}}>
           <Image src={u25} style={{height:60, width:60, marginBottom:15 }} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>
              <Col  style={{height:70,color: '#1A2B6E',fontSize:19, fontWeight: 600, fontFamily:'Poppins, sans-serif', paddingBottom:15}} >
              {t("service1-0")}
              </Col>  
              <Col xs={12} style={{color: '#1A2B6E', fontSize:13, fontWeight: 400, fontFamily:'Poppins, sans-serif'}}  className="my-auto">
              {t("service1-1")}
              </Col> 
              
            </Col>  
            <Col xs={10} lg="2" style={{backgroundColor:'rgba(231, 251, 249, 1)', marginTop:70, marginBottom: 0, padding:40, borderRadius:3, marginInline:10}}>
              <Image src={u144} style={{height:60, width:60, marginBottom:15 }} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>
              <Col  style={{height:70,color: '#1A2B6E',fontSize:19, fontWeight: 600, fontFamily:'Poppins, sans-serif', paddingBottom:15}} >
              {t("service2-0")}
              </Col>  
              <Col xs={12} style={{color: '#1A2B6E', fontSize:13, fontWeight: 400, fontFamily:'Poppins, sans-serif'}} className="my-auto">
              {t("service2-1")}
              </Col> 
              
            </Col>  
            <Col xs={10} lg="2" style={{ backgroundColor:'rgba(231, 251, 249, 1)', marginTop:70, marginBottom: 0, padding:40, borderRadius:3, marginInline:10}}>
              <Image src={u145} style={{height:60, width:60, marginBottom:15 }} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>
              <Col style={{height:70,color: '#1A2B6E',fontSize:19, fontWeight: 600, fontFamily:'Poppins, sans-serif', paddingBottom:15}} >
              {t("service3-0")}
              </Col>  
              <Col xs={12}  style={{color: '#1A2B6E', fontSize:13, fontWeight: 400 , fontFamily:'Poppins, sans-serif'}} className="my-auto">
              {t("service3-1")}
              </Col> 
            </Col>  
            <Col xs={10}  lg="2" style={{ backgroundColor:'rgba(231, 251, 249, 1)', marginTop:70, marginBottom: 0, padding:40, borderRadius:3, marginInline:10}}>
              <Image src={u26} style={{height:60, width:60, marginBottom:15 }} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>
              <Col style={{height:70,color: '#1A2B6E',fontSize:19, fontWeight: 600, fontFamily:'Poppins, sans-serif', paddingBottom:15}} >
              {t("service4-0")}
              </Col>  
              <Col xs={12}  style={{color: '#1A2B6E', fontSize:13, fontWeight: 400 , fontFamily:'Poppins, sans-serif'}} className="my-auto">
              {t("service4-1")}
              </Col> 
            </Col>  
            <span id="offre"></span>
            </Row>

{/* //---------------------OFFRES------------------------- */}
{/* //---------------------OFFRES------------------------- */}
{/* //---------------------OFFRES------------------------- */}

      <Col lg={{span: 4, offset: 4 }} style={{ marginTop:50 , paddingBottom: 0}}>
        <Col className="text-center" style={{margin:0}}><span style={{color: '#1A2B6E', fontSize:26, fontWeight: 700, fontFamily:'Poppins, sans-serif'}}>{t("offres1")}</span><span style={{color: '#3BB9A3', fontSize:26, fontWeight: 500, fontFamily:'Poppins, sans-serif'}}>{t("offres2")}</span></Col>
        <Col className="text-center" style={{marginInline:50}}><span style={{color: '#626981', fontSize:16, fontWeight: 400, fontFamily:'Poppins, sans-serif'}}>{t("offres3")}</span></Col>
      </Col>

     
      <Row  className="justify-content-center"  style={{ paddingBottom:50}}>
           <Col xs={10}  lg="2" style={{ marginTop:40, marginBottom: 40, padding:30, borderRadius:10, marginInline:10, boxShadow:'0px 0px 15px rgba(26, 43, 110, 0.4)'}}>
              <Col  style={{color: '#3BB9A3',fontSize:20, fontWeight: 600, fontFamily:'Poppins, sans-serif', paddingBottom:0}} >
              {t("offres1-0")}
              </Col>  
              <Col style={{color: `#3BB9A3`, borderRadius:15, paddingInline:0, borderStyle:'solid', fontSize:14, fontWeight: 400, fontFamily:'Poppins, sans-serif', borderWidth:0, borderColor: `#6385FF`, paddingBottom:10}} >
              {t("offres1-1")}
              </Col> 
              <div style={{height:1, backgroundColor: '#D3D3D3'}}></div>
              <Col xs={12}  style={{color: 'black', fontSize:17, fontWeight: 500 , fontFamily:'Poppins, sans-serif'}} className="my-auto">
              <Col style={{marginBlock:20}}>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u212} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres1-2")}</Col>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u212} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres1-3")}</Col>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u212} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres1-4")}</Col>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u212} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres1-5")}</Col>
                <Col className="text-start" style={{color:'#3BB9A3', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u226} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres1-6")}</Col>
        </Col> 
              </Col> 
              <Col className="text-center" style={{marginTop:40}}>
              <Col style={{color:'#3BB9A3', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,lineHeight:1.4}}>{t("offres1-7")}</Col>
              <Col style={{color:'#3BB9A3', fontSize:24,fontWeight: '700', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}>2000€ HT</Col>
                <Button variant="outline-light" href="/contact"  style={{borderWidth:0, background:  `#3BB9A3`, borderRadius:30, paddingInline:40, paddingBlock:15, fontSize:17,fontWeight: '600', fontFamily:'Poppins, sans-serif'}}>{t("btn5")}</Button>
              </Col> 
            </Col> 
            <Col xs={10}  lg="2" style={{ marginTop:40, marginBottom: 40, padding:30, borderRadius:10, marginInline:10, boxShadow:'0px 0px 15px rgba(26, 43, 110, 0.4)'}}>
              
              <Col  style={{color: '#EBC503',fontSize:20, fontWeight: 600, fontFamily:'Poppins, sans-serif', paddingBottom:0}} >
              {t("offres2-0")}
              </Col>  
              <Col style={{color: `#EBC503`, borderRadius:15, paddingInline:0, borderStyle:'solid', fontSize:14, fontWeight: 400, fontFamily:'Poppins, sans-serif', borderWidth:0, borderColor: `#6385FF`, paddingBottom:10}} >
              {t("offres2-1")}
              </Col> 
              <div style={{height:1, backgroundColor: '#D3D3D3'}}></div>
              <Col xs={12}  style={{color: 'black', fontSize:17, fontWeight: 500 , fontFamily:'Poppins, sans-serif'}} className="my-auto">
              <Col style={{marginBlock:20}}>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u192} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres2-2")}</Col>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u192} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres2-3")}</Col>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u192} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres2-4")}</Col>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u192} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres2-5")}</Col>
                <Col className="text-start" style={{color:'#EBC503', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u228} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres2-6")}</Col>
        </Col> 
              </Col> 
              <Col className="text-center" style={{marginTop:40}}>
              <Col style={{color:'#EBC503', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,lineHeight:1.4}}>{t("offres1-7")}</Col>
              <Col style={{color:'#EBC503', fontSize:24,fontWeight: '700', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}>3000€ HT</Col>
                <Button variant="outline-light" href="/contact"  style={{borderWidth:0, background:  `rgba(235, 197, 3, 1)`, borderRadius:30, paddingInline:40, paddingBlock:15, fontSize:17,fontWeight: '600', fontFamily:'Poppins, sans-serif'}}>{t("btn5")}</Button>
              </Col>
            </Col>  
            <Col xs={10}  lg="2" style={{ marginTop:40, marginBottom: 40, padding:30, borderRadius:10, marginInline:10, boxShadow:'0px 0px 15px rgba(26, 43, 110, 0.4)'}}>
              
            <Col style={{color: `#DC0073`,fontSize:20, fontWeight: 600, fontFamily:'Poppins, sans-serif', paddingBottom:0}} >
            {t("offres3-0")}
              </Col>  
              <Col style={{color: `#DC0073`, borderRadius:15, paddingInline:0, borderStyle:'solid', fontSize:14, fontWeight: 400, fontFamily:'Poppins, sans-serif', borderWidth:0, borderColor: `#6385FF`, paddingBottom:10}} >
              {t("offres3-1")}
              </Col> 
              <div style={{height:1, backgroundColor: '#D3D3D3'}}></div>
              <Col xs={12}  style={{color: 'black', fontSize:17, fontWeight: 500 , fontFamily:'Poppins, sans-serif'}} className="my-auto">
              <Col style={{marginBlock:20}}>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u218} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres3-2")}</Col>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u218} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres3-3")}</Col>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u218} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres3-4")}</Col>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u218} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres3-5")}</Col>
                <Col className="text-start" style={{color:'#DC0073', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={u230} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres3-6")}</Col>
        </Col> 
              </Col> 
              <Col className="text-center" style={{marginTop:40}}>
              <Col style={{color:'#DC0073', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,lineHeight:1.4}}>{t("offres1-7")}</Col>
              <Col style={{color:'#DC0073', fontSize:24,fontWeight: '700', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}>4000€ HT</Col>
                <Button variant="outline-light" href="/contact"  style={{borderWidth:0, background:  `#DC0073`, borderRadius:30, paddingInline:40, paddingBlock:15, fontSize:17,fontWeight: '600', fontFamily:'Poppins, sans-serif'}}>{t("btn5")}</Button>
              </Col>
            </Col>  
         
            <Col xs={10}  lg="2" style={{ marginTop:40, marginBlock:width > 1440 ? -3:0, marginBottom: 40, padding:30, borderRadius:10, marginInline:10, boxShadow:'0px 0px 15px rgba(129, 143, 225, 0.8)'}}>
              <Col style={{color: `#6385FF`,fontSize:20, fontWeight: 600, fontFamily:'Poppins, sans-serif', paddingBottom:0}} >
              {t("offres4-0")}
              </Col>  
              <Col style={{paddingBottom:10}}>
              <span style={{color: `#6385FF`, borderRadius:15, paddingInline:20, borderStyle:'solid', fontSize:14, fontWeight: 400, fontFamily:'Poppins, sans-serif', borderWidth:1, borderColor: `#6385FF`}} >
              {t("offres4-1")}
              </span> </Col>
              <div style={{height:1, backgroundColor: '#D3D3D3'}}></div>
              <Col xs={12}  style={{color: 'black', fontSize:17, fontWeight: 500 , fontFamily:'Poppins, sans-serif'}} className="my-auto">
              <Col style={{marginBlock:20}}>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.5}}><Image src={u239} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres4-2")}</Col>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.5}}><Image src={u239} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres4-3")}</Col>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.5}}><Image src={u239} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres4-4")}</Col>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.5}}><Image src={u239} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres4-5")}</Col>
                <Col className="text-start" style={{color:'#1A2B6E', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.5}}><Image src={u239} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres4-6")}</Col>
                <Col className="text-start" style={{color:'#6385FF', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.5}}><Image src={u232} style={{borderRadius:0, height:15, width:15, marginRight:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("offres4-8")}</Col>
        </Col> 
              </Col> 
              <Col className="text-center" style={{marginTop:60}}>
              <Col style={{color:'#6385FF', fontSize:12,fontWeight: '400', fontFamily:'Poppins, sans-serif' ,lineHeight:1.4}}>{t("offres4-7")}</Col>
              <Col style={{color:'#6385FF', fontSize:24,fontWeight: '700', fontFamily:'Poppins, sans-serif' ,marginBottom:20,lineHeight:1.2}}></Col>
                <Button variant="outline-light" href="/contact"  style={{borderWidth:0, background:  `#6385FF`, borderRadius:30, paddingInline:40, paddingBlock:15, fontSize:17,fontWeight: '600', fontFamily:'Poppins, sans-serif'}}>{t("btn5")}</Button>
              </Col>
            </Col>  
           
            </Row>
            <Row style={{justifyContent:'center',alignItems:'center', background: 'rgba(59, 185, 163, 1)', paddingBlock:30}}>
           <Col className="text-center" style={{ paddingBlock:20}}>
              <Image src={logo_BD} style={{ height:40}} alt='Becton Dickinson'/>
            </Col>  
            <Col className="text-center" style={{ paddingBlock:20}}>
              <Image src={logo_diabeloop} style={{ height:40}} alt='Diabeloop'/>
            </Col>  
            <Col className="text-center" style={{ paddingBlock:20}}>
              <Image src={logo_carmat} style={{ height:40}} alt='Carmat'/>
            </Col>  
            <Col className="text-center" style={{ paddingBlock:20}}>
              <Image src={logo_fillmed} style={{ height:40}} alt='Fillmed'/>
            </Col>  
            <Col className="text-center" style={{ paddingBlock:20}}>
              <Image src={logo_BD} style={{ height:40}} alt='Becton Dickinson'/>
            </Col>  
            <Col className="text-center" style={{ paddingBlock:20}}>
              <Image src={logo_diabeloop} style={{ height:40}} alt='Diabeloop'/>
            </Col>  
            <Col className="text-center" style={{ paddingBlock:20}}>
              <Image src={logo_carmat} style={{ height:40}} alt='Carmat'/>
            </Col>  
            <Col className="text-center" style={{ paddingBlock:20}}>
              <Image src={logo_fillmed} style={{ height:40}} alt='Fillmed'/>
            </Col>  
      </Row>

           
            <Col    style={{ marginTop:0 , paddingTop: 50, backgroundColor:'rgba(249, 253, 252, 1)'}}>
        <Col className="text-center" style={{margin:0}}><span style={{color: '#1A2B6E', fontSize:22, fontWeight: 700, fontFamily:'Poppins, sans-serif'}}>FAQ</span></Col>
      </Col>
 <Row  className="justify-content-center"  style={{ paddingBlock:20,paddingBottom:60, backgroundColor:'rgba(249, 253, 252, 1)'}}>
          <Col lg={{span: 8, offset: 0 }}>
            <Accordion>
      <Accordion.Item eventKey="0" style={{margin:15,borderWidth:0, boxShadow:'0px 0px 15px rgba(26, 43, 110, 0.3)'}}>
        <Accordion.Header><span style={{color: '#1A2B6E', fontSize:18, fontWeight: 400, fontFamily:'Poppins, sans-serif'}}>{t("FAQ1-0")} <span style={{fontWeight: 700}}>{t("FAQ1-1")}</span>{t("FAQ1-2")}</span></Accordion.Header>
        <Accordion.Body>
        {t("FAQ1-3")}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" style={{margin:15,borderWidth:0, boxShadow:'0px 0px 15px rgba(26, 43, 110, 0.3)'}}>
      <Accordion.Header><span style={{color: '#1A2B6E', fontSize:18, fontWeight: 400, fontFamily:'Poppins, sans-serif'}}>{t("FAQ2-0")} <span style={{fontWeight: 700}}>{t("FAQ2-1")}</span>{t("FAQ2-2")}</span></Accordion.Header>
        <Accordion.Body>
        {t("FAQ2-3")}
        </Accordion.Body>
      </Accordion.Item>
  
    
      <Accordion.Item eventKey="2" style={{margin:15,borderWidth:0, boxShadow:'0px 0px 15px rgba(26, 43, 110, 0.3)'}}>
      <Accordion.Header><span style={{color: '#1A2B6E', fontSize:18, fontWeight: 400, fontFamily:'Poppins, sans-serif'}}>{t("FAQ3-0")} <span style={{fontWeight: 700}}>{t("FAQ3-1")}</span>{t("FAQ3-2")}</span></Accordion.Header>
        <Accordion.Body>
        {t("FAQ3-3")}
        </Accordion.Body>
      </Accordion.Item>
    
      <Accordion.Item eventKey="3" style={{margin:15,borderWidth:0, boxShadow:'0px 0px 15px rgba(26, 43, 110, 0.3)'}}>
      <Accordion.Header><span style={{color: '#1A2B6E', fontSize:18, fontWeight: 400, fontFamily:'Poppins, sans-serif'}}>{t("FAQ4-0")} <span style={{fontWeight: 700}}>{t("FAQ4-1")}</span>{t("FAQ4-2")}</span></Accordion.Header>
        <Accordion.Body>
        {t("FAQ4-3")}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>


    </Col>
    </Row>  

        
    </Container>
  
      
    </>

  );
}

export default Home